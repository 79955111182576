import React from "react"
import PropTypes from 'prop-types'
import { graphql } from "gatsby"
// ---------------------------------------------
import Articles from "../components/articles"
import Layout from "../components/layout"
import Revealer from "../components/revealer"
import SEO from "../components/seo"
// ---------------------------------------------

const JournalPage = ({ data }) => {

  const articles = data.allContentfulArticle.edges
  const bg = articles[0].node.backgroundColour || '#F9F9F4'

  return (
    <Layout footerColour="#FFFFFF" hasNewsletter>
      <SEO title="Journal" />

      <Revealer>
        <div className="innerx4" style={{
          backgroundColor: bg
        }}>

          <section className="innerx4 align-center">
            <h1 className="upcase">The Journal</h1>
            <h1>Impressions of Making</h1>
          </section>

          <section className="no-bullets">
            <Articles articles={articles} />
          </section>
        </div>
      </Revealer>

    </Layout>
  )
}

JournalPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default JournalPage

export const pageQuery = graphql`
  query JournalPageQuery {
    allContentfulArticle (
      sort: {
        fields: [publishDate],
        order: DESC
      }
    ) {
      edges {
        node {
          ...Article
        }
      }
    }
  }
`
